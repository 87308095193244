import React , {useState} from 'react';
import {MdOutlineMenu} from 'react-icons/md';
import {AiOutlineClose} from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const Navbar = ({ nav, handleNav }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className='flex justify-between items-center h-24  mx-auto px-4  text-white bg-[#0e6248]'>
      <h1 className='w-[50%] md:text-base text-xs lg:text-lg font-bold text-[#00df9a] m-4'>{t('companyName')}</h1>
      <ul className="w-[50%] hidden md:flex md:justify-end md:text-base lg:text-lg">
        <li className="p-4">
          <a href="/">{t('home')}</a>
        </li>
        <li className="p-4">
          <a href="/about">{t('about')}</a>
        </li>
        <li className="p-4">
          <a href="/contact">{t('contact')}</a>
        </li>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
        {!nav ? <AiOutlineClose size={20}/> : <MdOutlineMenu size={25}/>}
      </div>
      <div className={!nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#0e6248] ease-in-out duration-500 md:hidden' : 'fixed left-[-9000px]'}>
        <ul className='uppercase p-4 pt-16'>
          <li className="p-4 border-b border-gray-300"> <a href="/">{t('home')}</a></li>
          <li className="p-4 border-b border-gray-300"> <a href="/about">{t('about')}</a></li>
          <li className="p-4 border-b border-gray-300"> <a href="/contact">{t('contact')}</a> </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
