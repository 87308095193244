import React from "react";
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const { t, i18n } = useTranslation();

  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-[90vh] mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] font-bold pt-40 text-2xl md:text-4xl">
          {t('contactUs')}
        </p>
        <div className="mt-6">
          <p className="text-gray-400 text-base">
            {t('contactText')}
          </p>
        </div>
     
        <div className="">
          <p className="text-[#00df9a] md:text-4xl sm:text-3xl  text-2xl p-2 font-bold py-6">
            {t('address')}
          </p>
          <p className="md:text-2xl text-xl font-bold text-gray-500 p-2">
            {t('addressValue')}
          </p>{" "}
        </div>
        <div className="">
          <p className="text-[#00df9a] md:text-4xl sm:text-3xl  text-2xl p-2 font-bold pt-6">
            {t('phoneNumber')}
          </p>
          <p className="md:text-2xl text-xl font-bold text-gray-500 p-4">
            {t('phoneNumberValue')}
          </p>{" "}
        </div>
        <div className="">
          <p className="text-[#00df9a] md:text-4xl sm:text-3xl  text-2xl p-2 font-bold pt-6">
            {t('email')}
          </p>
          <p className="md:text-2xl text-xl font-bold text-gray-500 p-4">
            {t('emailValue')}
          </p>{" "}
        </div>
      </div>
    </div>
  );
}
