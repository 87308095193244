import React from "react";
import background from "../assets/bg.png";
import photo from "../assets/photo.png";
import { useTranslation } from 'react-i18next';

function Mission({ nav }) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          width: "100%",
          backgroundSize: "cover",
        }}
        className="w-full bg-[#00df9a] mx-auto text-center flex flex-col items-center justify-end h-[50vh] md:h-[70vh] lg:h-[70vh]"
      >
        <div className={`w-1/4  p-4 rounded-lg opacity-90 ${!nav ? 'hidden' : 'bg-black'}`}>
          <p className="text-white text-2xl md:text-4xl font-bold">{t('mission')}</p>
        </div>
        <div className={`w-[80vw] mb-4 p-4 rounded-lg opacity-80 lg:opacity-90 ${!nav ? 'hidden' : 'bg-[#00df9a]'}`}>
          <p className="text-black text-sm lg:text-xl">
            {t('missionDescription')}
          </p>
        </div>
      </div>
      <div className="w-full  mt-20 bg-black mx-auto text-center flex flex-col items-center justify-center h-[40vh]">
        <div className={`w-full  p-4 rounded-lg opacity-90 ${!nav ? 'hidden' : 'bg-black'}`}>
          <p className="text-white text-xl md:text-4xl font-bold ">{t('managingDirector')}</p>
          <div className="mt-6">
            <img src={photo} alt="Managing Director" className="rounded-full w-32 h-32 mx-auto" />
          </div>
        </div>
        <div className="mt-6">
          <p className="text-gray-400 text-xl md:text-4xl">
            {t('directorName')}
          </p>
        </div>
        <blockquote className="mt-4 text-white text-base md:text-2xl italic">
         " {t('directorQuote')} "
        </blockquote>
      </div>
    </div>
  );
}

export default Mission;
