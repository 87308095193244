import React from 'react';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className='bg-black w-full mx-auto pt-16 grid grid-cols-3 lg:grid-cols-3 gap-0 text-gray-300'>
      <div className='bg-[#0e6248] p-4 flex justify-center'>
        <ul>
          <li className='py-1 text-xl text-[#daf8ef]'>{t('switchTo')}</li>
          <li
            className='pt-2 text-sm cursor-pointer'
            onClick={() => handleLanguageChange('en')}
          >
            {t('english')}
          </li>
          <li
            className='py-1 text-sm cursor-pointer'
            onClick={() => handleLanguageChange('ja')}
          >
           {t('japanese')}
          </li>
        </ul>
      </div>
      <div className='bg-[#0e6248] p-4 flex justify-center'>
        <ul>
          <li className='py-1 text-xl text-[#daf8ef]'>{t('navigateTo')}</li>
          <li className='pt-2 text-sm'>
            {' '}
            <a href='/about'> {t('servicesOffered')}</a>
          </li>
          <li className='py-1 text-sm'>
            {' '}
            <a href='/contact'> {t('contactUs')}</a>
          </li>
        </ul>
      </div>
      <div className='bg-[#0e6248] px-4 flex justify-center'>
        <div>
          <h1 className='p-4 md:text-base text-xs lg:text-lg font-bold text-[#00df9a]'>
            Nippon Nepal Trading & Consultants (P) Ltd.
          </h1>
          <p className='px-4 text-xs'>
            {t('copyright')} Nippon Nepal Trading & Consultants (P) Ltd.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
