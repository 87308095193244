import React from "react";
import { RiLeafFill } from 'react-icons/ri';
import { FaBuilding, FaWater, FaLightbulb, FaIndustry, FaGlobeAsia, FaNetworkWired, FaLandmark, FaUserFriends, FaGavel, FaChartLine, FaFlask, FaTools, FaBriefcase, FaHandshake, FaTrash, FaHospital, FaHandsHelping, FaCoins, FaTree, FaSeedling, FaUsers, FaCogs, FaDesktop, FaCity, FaUmbrellaBeach } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';


export default function About() {
  const { t, i18n } = useTranslation();

  const services = [
    {
      title: t('envProjectTitle'),
      icon: <RiLeafFill size={40}/>,
    },
    {
      title: t('consultingTitle'),
      icon: <FaBriefcase size={40} />,
    },
    {
      title: t('waterResourceTitle'),
      icon: <FaWater size={40} />,
    },
    {
      title: t('disasterRiskTitle'),
      icon: <RiLeafFill size={40} />,
    },
    {
      title: t('transportInfrastructureTitle'),
      icon: <FaBuilding size={40} />,
    },
    {
      title: t('cityPlanningTitle'),
      icon: <FaCity size={40} />,
    },
    {
      title: t('energyPowerWaterTitle'),
      icon: <FaLightbulb size={40} />,
    },
    {
      title: t('architectureTitle'),
      icon: <FaLandmark size={40} />,
    },
    {
      title: t('facilityEngineeringTitle'),
      icon: <FaTools size={40} />,
    },
    {
      title: t('digitalTechnologyTitle'),
      icon: <FaNetworkWired size={40} />,
    },
    {
      title: t('geotechnicalTitle'),
      icon: <FaGavel size={40} />,
    },
    {
      title: t('surveyResearchTitle'),
      icon: <FaChartLine size={40} />,
    },
    {
      title: t('infrastructureDevelopmentTitle'),
      icon: <FaIndustry size={40} />,
    },
    {
      title: t('careerSkillDevelopmentTitle'),
      icon: <FaBriefcase size={40} />,
    },
    {
      title: t('facilitatorsProgramsTitle'),
      icon: <FaHandshake size={40} />,
    },
    {
      title: t('wasteManagementTitle'),
      icon: <FaTrash size={40} />,
    },
    {
      title: t('healthCareTitle'),
      icon: <FaHospital size={40} />,
    },
    {
      title: t('socialWelfareTitle'),
      icon: <FaHandsHelping size={40} />,
    },
    {
      title: t('povertyElevationTitle'),
      icon: <FaCoins size={40} />,
    },
    {
      title: t('sustainableDevelopmentTitle'),
      icon: <FaTree size={40} />,
    },
    {
      title: t('modernAgricultureTitle'),
      icon: <FaSeedling size={40} />,
    },
    {
      title: t('hrSolutionTitle'),
      icon: <FaUsers size={40} />,
    },
    {
      title: t('itSystemDevelopmentTitle'),
      icon: <FaCogs size={40} />,
    },
    {
      title: t('smartCityProjectsTitle'),
      icon: <FaDesktop size={40} />,
    },
    {
      title: t('tourismIndustryTitle'),
      icon: <FaUmbrellaBeach size={40} />,
    },
  ];

  return (
    <div className="text-white">
      <div className="max-w-[1240px] mt-[20px] w-full h-full mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] font-bold pt-6 text-2xl md:text-4xl">
          {t('aboutUsTitle')}
        </p>
        <div className="mt-6">
          <p className="text-gray-400">
          {t('aboutUsDescription')}
          </p>
        </div>

        <p className="text-[#00df9a]  md:text-4xl text-xl p-2 font-bold pt-10">
        {t('servicesTitle')}
        </p>

        <div className='max-w-[1240px] overflow-auto mx-auto grid md:grid-cols-5 gap-8'>
          {services.map((service, index) => (
            <div className='w-full bg-[#cdf5e8] text-gray-800 rounded-md font-medium p-4 mt-4' key={index}>
              <ul className="mx-auto flex justify-center">
                {service.icon}
              </ul>
              <h2 className="text-base font-bold text-center ">{service.title}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
