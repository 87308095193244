import React , {useState} from 'react'
import Navbar from './components/Navbar';
import { Hero } from './components/Hero';
import Mission from './components/Mission';
import Footer from './components/Footer';
import {Route, Routes} from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import { useTranslation, Trans } from 'react-i18next';

function App() {
  const [nav, setNav] = useState(true);
  const { t, i18n } = useTranslation();

  const handleNav =()=>{
    setNav(!nav)
  }
  return (
    <div >
      <Navbar nav={nav} handleNav={handleNav}/>
      <Routes>
        <Route path='/' element={<div>
          <Hero/>
      <Mission  nav={nav}/>
        </div>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact
              t={t}
              i18n={i18n}
            />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
